import WebServiceRequest from '../Api/WebServiceRequest'

class GetAllCoupons extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('GET')
    super.setTag('Coupons/GetCoupons')
  }

  setParams(data) {
    super.setRequestParam(data)
  }
}
class GetCoupon extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('GET')
    super.setTag('Coupons/GetCouponById')
  }

  setParams(data) {
    super.setRequestParam(data)
  }
}

class CreateCoupon extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('POST')
    super.setTag('Coupons/CreateCoupon')
  }

  setParams(data) {
    super.setRequestParamDataObj(data)
  }
}

class UpdateCoupon extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('PUT')
    super.setTag('Coupons/UpdateCoupon')
  }

  setParams(data) {
    super.setRequestParamDataObj(data)
  }
}

class DeleteCoupon extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('DELETE')
    super.setTag('Coupons/DeleteCoupon')
  }

  setParams(data) {
    super.setRequestParam(data)
  }
}

export {
  DeleteCoupon,
  UpdateCoupon,
  CreateCoupon,
  GetAllCoupons,
  GetCoupon

}
