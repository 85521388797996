import WebServiceRequest from '../Api/WebServiceRequest'

class GetAllCurrencies extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('GET')
    super.setTag('Currency')
  }

  setParams(data) {
    super.setRequestParam(data)
  }
}

class CreateCurrency extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('POST')
    super.setTag('Currency')
  }

  setParams(data) {
    super.setFormData(data)
  }
}

class UpdateCurrency extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('PUT')
    super.setTag('Currency')
  }

  setParams(data) {
    super.setFormData(data)
  }
}

class DeleteCurrency extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('DELETE')
    super.setTag('Currency')
  }

  setParams(data) {
    super.setRequestParam(data)
  }
}

export {
  GetAllCurrencies,
  DeleteCurrency,
  UpdateCurrency,
  CreateCurrency

}
